import NextLink, {LinkProps} from 'next/link';
import {Flex, FlexProps} from '@chakra-ui/react';

import {useTranslate} from '@eksab/i18n';

import {CirclesGridIcon} from '../icons';

export function ViewAllButton(props: LinkProps & FlexProps) {
  const t = useTranslate();

  return (
    <Flex
      className="view-all-button"
      as={NextLink}
      px="3"
      py="2"
      align="center"
      gap="5"
      fontSize={['xs', 'sm', 'md']}
      fontWeight="600"
      color="#2a2e3a"
      rounded="xl"
      borderWidth="1px"
      borderColor="gray.400"
      transition="color 0.3s, border-color 0.3s, background 0.3s"
      _hover={{color: 'green.400', borderColor: 'currentColor'}}
      _active={{bg: 'green.100', color: '#2a2e3a', borderColor: 'transparent'}}
      {...props}
    >
      {t('profile.my-badges.all')}

      <CirclesGridIcon color="inherit" />
    </Flex>
  );
}
