import {InfiniteData, useInfiniteQuery, useQueryClient} from '@tanstack/react-query';

import {api} from '@eksab/api';

import {Notification} from '../types';
import {useAccessToken} from '@eksab/hooks';

type Response = {
  data: Notification[];
  meta: {current_page: number; last_page: number; new_notification_count: number};
};

const queryKey = ['notifications'];

export function useNotificationsInfiniteQuery() {
  const accessToken = useAccessToken().data;
  return useInfiniteQuery<Response>(
    queryKey,
    ({pageParam = 1}) =>
      api.get('v2/me/notifications', {params: {per_page: 7, page: pageParam}}).then((res) => res.data),
    {
      getNextPageParam: ({meta: {current_page, last_page}}) =>
        current_page < last_page ? current_page + 1 : undefined,
      enabled: !!accessToken,
    },
  );
}

export function useClearNumberOfNewNotifications() {
  const queryClient = useQueryClient();

  return () =>
    queryClient.setQueryData<InfiniteData<Response>>(queryKey, (data) => ({
      pageParams: data!.pageParams,
      pages: [
        {
          ...data!.pages[0],
          meta: {
            ...data!.pages[0].meta,
            new_notification_count: 0,
          },
        },
        ...data!.pages.slice(1),
      ],
    }));
}
