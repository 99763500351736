import {useIntl} from 'react-intl';
import {Center, Flex, FlexProps, Text, useDisclosure} from '@chakra-ui/react';
import dayjs from 'dayjs';

import {Artwork} from '@eksab/features/store/badges/components';

import {BadgeDetailsModal} from './BadgeDetails';
import {CardsIcon} from '../../icons';
import {TimeIcon} from '../icons';
import type {MyBadge} from '../types';

export const BadgeCard = ({badge, count, last_purchase, ...props}: MyBadge & FlexProps) => {
  const {isOpen: isBadgeDetailsOpen, onOpen: onOpenBadgeDetails, onClose: onCloseBadgeDetails} = useDisclosure();

  const format = useIntl().formatNumber;

  return (
    <>
      <Flex
        flexDirection="column"
        gap={{base: 2, lg: 6}}
        px={{base: '2', lg: '4'}}
        pt={{base: '8', lg: '10'}}
        pb={{base: 4, lg: 6}}
        w="full"
        maxW={{base: '14rem', lg: '22rem'}}
        rounded="3xl"
        bg="#EAEAEA"
        pos="relative"
        cursor="pointer"
        onClick={onOpenBadgeDetails}
        {...props}
      >
        <Flex justify="space-between" gap="4" rounded="3xl">
          <Artwork artwork={badge.artwork} gradient={badge.gradient} width="65%" />

          <Center flexDir="column" gap={{base: 1.5, lg: 2}}>
            <Center bg="white" boxSize={{base: '1.625rem', lg: '3.125rem'}} borderRadius="full">
              <CardsIcon width="62%" height="auto" />
            </Center>

            <Text fontSize={{base: 'xs', lg: '2xl'}} fontWeight="600">
              {format(count)}
            </Text>
          </Center>
        </Flex>

        <Text
          fontSize={{base: 'xs', lg: 'md'}}
          fontWeight="600"
          color="#8F8F8F"
          bg="white"
          rounded="xl"
          px="2.5"
          py="1.5"
          noOfLines={2}
        >
          {badge.name}
        </Text>

        <Center mt="auto" gap={{base: 1.5, lg: 3.5}}>
          <TimeIcon boxSize={{base: 4, lg: 8}} />

          <Text fontSize={{base: 'x-small', lg: 'md'}} fontWeight={{base: 600, lg: 700}} color="#6C6C6C">
            {dayjs().to(last_purchase)}
          </Text>
        </Center>
      </Flex>

      <BadgeDetailsModal
        isOpen={isBadgeDetailsOpen}
        onClose={onCloseBadgeDetails}
        badge={badge}
        count={count}
        last_purchase={last_purchase}
      />
    </>
  );
};
