import NextLink from 'next/link';
import {useRouter} from 'next/router';
import {Box, IconButton, Avatar, Text, Flex} from '@chakra-ui/react';

import {useTranslate} from '@eksab/i18n';
import {CoinsIcon} from '@eksab/assets/icons';
import {formatCoins} from '@eksab/util/helpers';

import {EarnedBadgesCount} from '@eksab/features/profile/badges/components';
import {useNotificationsInfiniteQuery} from '@eksab/features/profile/hooks/useNotificationsInfiniteQuery';

import {NewBadge} from '../NewBadge';
import {icons} from './icons';

export const UserInfo = ({me: {coins, name, photo, completed_milestones}}: {me: Me}) => {
  const router = useRouter();

  const t = useTranslate();
  const notificationsQuery = useNotificationsInfiniteQuery();

  const newNotificationsCount = notificationsQuery.data?.pages[0]?.meta.new_notification_count;
  const inProfile = router.pathname === '/profile';
  const inNotifications = router.pathname === '/profile/notifications';

  return (
    <Flex gap="1.5">
      <EarnedBadgesCount
        count={completed_milestones}
        as={NextLink}
        href="/profile/my-badges/earned"
        pos="relative"
        color={['#2a2e3a', null, null, 'white']}
        bg="transparent"
      >
        <NewBadge size="sm" top="0" left="50%" transform="auto" translateX="-50%" translateY="-115%" />
      </EarnedBadgesCount>

      <Flex
        as={NextLink}
        href="/store"
        align="center"
        gap="1.5"
        h="8"
        px="2.5"
        py="1.5"
        bgColor={['unset', null, null, '#191B20']}
        borderRadius="full"
        borderWidth={'1px'}
        borderColor={['#EAEAEA', null, null, '#6C6C6C']}
      >
        <Text
          flexShrink={0}
          fontSize={['xs', 'sm', 'xs', null, 'sm']}
          fontWeight="700"
          color={['#2A2E3A', null, null, 'white']}
        >
          {formatCoins(coins)}
        </Text>

        <CoinsIcon flexShrink={5} minW="2" maxW="5" h="auto" />
      </Flex>

      <Flex
        as={NextLink}
        display={{base: 'none', lg: 'flex'}}
        href="/profile"
        borderRadius="full"
        bgColor={inProfile ? '#2A2E3A' : '#191B20'}
        borderWidth="1px"
        borderColor={inProfile ? 'green.400' : '#6C6C6C'}
        gap="2"
        align="center"
        px="2.5"
        maxW="28"
      >
        <Text
          dir="auto"
          fontSize="xs"
          fontWeight="600"
          color="white"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {name}
        </Text>

        <Avatar
          title={t('app.profile')}
          alignSelf="center"
          data-testid="profile_picture"
          boxSize="6"
          minW="unset"
          objectFit="cover"
          bg="unset"
          src={photo?.thumb ?? photo?.original}
          iconLabel={name}
          icon={
            <icons.ProfileIcon
              boxSize={6}
              stroke={inProfile ? 'green.400' : 'white'}
              fill={inProfile ? 'green.400' : 'none'}
            />
          }
        />
      </Flex>

      <Box
        pos="relative"
        color="whiteAlpha.500"
        _hover={{color: 'white'}}
        cursor="pointer"
        onClick={() => router.push('/profile/notifications')}
      >
        <IconButton
          title={t('profile.notifications')}
          aria-label={t('profile.notifications')}
          boxSize="8"
          borderWidth={'1px'}
          borderColor={inNotifications ? 'green.400' : ['#EAEAEA', null, null, '#6C6C6C']}
          bgColor={['white', null, null, inNotifications ? '#2A2E3A' : '#191B20']}
          minW="unset"
          fill="unset"
          variant="unstyled"
          color="inherit"
          icon={
            <icons.NotificationsIcon
              newNotifications={!!newNotificationsCount}
              boxSize={6}
              stroke={inNotifications ? 'green.400' : ['#2A2E3A', null, null, 'white']}
              fill={inNotifications ? 'green.400' : ['#EAEAEA', null, null, 'none']}
            />
          }
        />
      </Box>
    </Flex>
  );
};
