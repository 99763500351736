import {Icon, IconProps, useBreakpointValue} from '@chakra-ui/react';

const ProfileIcon = (props: IconProps) => {
  const strokeWidth = useBreakpointValue(['1', null, null, '1.5']) as string;

  return (
    <Icon boxSize={6} viewBox="0 0 25 24" strokeWidth={strokeWidth} {...props}>
      <path
        d="M19.5 18.5C19.5 20.0294 16.5 21 12.5 21C8.5 21 5.5 20.0294 5.5 18.5C5.5 16.2692 9 15 12.5 15C16 15 19.5 16.5 19.5 18.5Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 12C14.9853 12 17 9.98528 17 7.5C17 5.01472 14.9853 3 12.5 3C10.0147 3 8 5.01472 8 7.5C8 9.98528 10.0147 12 12.5 12Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

const HomeIcon = ({active, ...props}: IconProps & {active: boolean}) => {
  return (
    <Icon boxSize={6} viewBox="0 0 25 24" fill="none" {...props}>
      <path
        d="M21.5 15.3969C21.5 19.9329 20.5 20.4329 12.5 20.4329C4.50003 20.4329 3.49999 19.4329 3.50003 15.3969C3.50006 13.2556 3.5 13.7515 3.5 11.8773C3.5 10.003 4.07474 8.93288 7.44411 6.22235C8.85369 5.0884 10.0725 4.17162 10.9601 3.52398C11.8925 2.84364 13.1264 2.81735 14.0609 3.49475C14.8853 4.09227 16.044 4.97476 17.5559 6.22235C21 9.06446 21.5 9.93288 21.5 11.8773C21.4999 15.6374 21.5 12.2556 21.5 15.3969Z"
        fill={active ? '#2A2E3A' : 'none'}
        stroke={active ? '#5AC57B' : '#6C6C6C'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 17.4329C9.875 15.9329 9.5 14.4329 12.5 14.4329C15.5 14.4329 15.125 15.9329 15.5 17.4329"
        stroke={active ? '#5AC57B' : '#6C6C6C'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export const NotificationsIcon = ({newNotifications, ...props}: IconProps & {newNotifications: boolean}) => {
  const badgeStroke = useBreakpointValue(['white', null, null, '#191B20']) as string;
  const strokeWidth = useBreakpointValue(['1', null, null, '1.5']) as string;
  return (
    <Icon width="24px" height="24px" viewBox="0 0 25 24" stroke="#2A2E3A" fill="none" {...props}>
      <path
        d="M10.5 20C10.5 20 11.1667 21 12.5 21C13.8333 21 14.5 20 14.5 20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M18.5 9C18.5 11 20.5 12 20.5 14C20.5 17 16.0556 18 12.5 18C8.94445 18 4.5 17 4.5 14C4.5 12 6.5 11.2414 6.5 9C6.5 6 8.50001 3 12.5 3C16.5 3 18.5 6 18.5 9Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {newNotifications && <circle cx="17.5" cy="7" r="4" fill="#EC1958" stroke={badgeStroke} strokeWidth="2" />}
    </Icon>
  );
};

const LanguageSwitchIcon = ({lang}: {lang: 'en' | 'ar'}) => {
  return (
    <Icon boxSize="8" viewBox="0 0 32 32" fill="none">
      {lang === 'ar' ? (
        <path
          d="M16.1787 24C14.5213 24 13.2329 23.6174 12.3134 22.8523C11.4052 22.0872 10.9512 21.007 10.9512 19.6118C10.9512 18.7229 11.2066 17.924 11.7174 17.2152C12.2396 16.5063 13.0683 15.8819 14.2035 15.3418V15.2574C13.4997 14.8298 12.9832 14.3572 12.654 13.8397C12.3361 13.3108 12.1772 12.7145 12.1772 12.0506C12.1772 11.5331 12.268 11.0323 12.4496 10.5485C12.6313 10.0534 12.8923 9.62025 13.2329 9.24895C13.5848 8.86639 14.0048 8.56259 14.493 8.33755C14.9925 8.11252 15.5544 8 16.1787 8C17.0188 8 17.7339 8.19128 18.3242 8.57384C18.9145 8.9564 19.3913 9.4571 19.7546 10.076L17.9326 11.6793C17.6829 11.2855 17.3934 10.9986 17.0642 10.8186C16.735 10.6273 16.3263 10.5316 15.8382 10.5316C15.2252 10.5316 14.7427 10.661 14.3908 10.9198C14.0502 11.1786 13.88 11.5612 13.88 12.0675C13.88 13.1027 14.7257 13.6203 16.4171 13.6203C16.735 13.6203 17.0755 13.6034 17.4388 13.5696C17.802 13.5359 18.205 13.4796 18.6478 13.4008L20.01 13.1646L20.4016 15.4937L18.171 15.865C16.3206 16.1688 14.9697 16.5851 14.1184 17.1139C13.267 17.6428 12.8413 18.3347 12.8413 19.1899V19.3924C12.8413 20.1013 13.1364 20.6245 13.7267 20.962C14.317 21.2996 15.1344 21.4684 16.1787 21.4684C16.9961 21.4684 17.802 21.3221 18.5967 21.0295C19.4027 20.7482 20.0951 20.3432 20.6741 19.8143L21.9512 21.8059C21.236 22.4923 20.3733 23.0267 19.3629 23.4093C18.3526 23.8031 17.2912 24 16.1787 24Z"
          fill="#5AC57B"
        />
      ) : (
        <>
          <path
            d="M15.3648 15.6623C15.3648 15.9478 15.3483 16.2498 15.3153 16.5683H8.10032C8.15523 17.4578 8.45722 18.1552 9.00631 18.6603C9.56638 19.1545 10.2418 19.4016 11.0325 19.4016C11.6804 19.4016 12.2185 19.2533 12.6468 18.9568C13.086 18.6493 13.3935 18.243 13.5692 17.7379H15.1836C14.942 18.6054 14.4588 19.3137 13.734 19.8628C13.0092 20.4009 12.1087 20.67 11.0325 20.67C10.1759 20.67 9.40715 20.4778 8.72628 20.0934C8.05639 19.7091 7.52926 19.1655 7.1449 18.4626C6.76054 17.7488 6.56836 16.9252 6.56836 15.9918C6.56836 15.0583 6.75505 14.2402 7.12843 13.5373C7.50181 12.8345 8.02344 12.2964 8.69333 11.923C9.3742 11.5386 10.1539 11.3465 11.0325 11.3465C11.889 11.3465 12.6468 11.5331 13.3057 11.9065C13.9646 12.2799 14.4697 12.7961 14.8212 13.455C15.1836 14.1029 15.3648 14.8387 15.3648 15.6623ZM13.8163 15.3493C13.8163 14.7783 13.69 14.2896 13.4375 13.8832C13.1849 13.4659 12.839 13.153 12.3997 12.9443C11.9714 12.7247 11.4937 12.6149 10.9666 12.6149C10.2088 12.6149 9.56089 12.8565 9.02279 13.3397C8.49566 13.8228 8.19366 14.4927 8.11679 15.3493H13.8163Z"
            fill="#5AC57B"
          />
          <path
            d="M21.7416 11.33C22.8398 11.33 23.7293 11.6649 24.4102 12.3348C25.091 12.9937 25.4315 13.9491 25.4315 15.2011V20.5217H23.9489V15.4152C23.9489 14.5147 23.7238 13.8283 23.2736 13.3561C22.8233 12.8729 22.2083 12.6313 21.4286 12.6313C20.6379 12.6313 20.0065 12.8784 19.5343 13.3726C19.073 13.8668 18.8424 14.5861 18.8424 15.5305V20.5217H17.3434V11.4947H18.8424V12.7796C19.1389 12.3183 19.5398 11.9614 20.0449 11.7089C20.5611 11.4563 21.1266 11.33 21.7416 11.33Z"
            fill="#5AC57B"
          />
        </>
      )}
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="url(#paint0_linear_1068_5578)" />
      <defs>
        <linearGradient
          id="paint0_linear_1068_5578"
          x1="16.4512"
          y1="0"
          x2="16.4512"
          y2="32"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5AC57B" />
          <stop offset="1" stopColor="#7A7A7A" />
        </linearGradient>
      </defs>
    </Icon>
  );
};

const EarnedBadges = (props: IconProps) => (
  <Icon width="22px" height="23px" viewBox="0 0 22 23" fill="none" {...props}>
    <path
      d="M10.1077 1.32735C10.7265 0.970084 11.4889 0.970085 12.1077 1.32735L20.3619 6.0929C21.0527 6.49176 21.4405 7.26303 21.3485 8.05545L20.2469 17.5503C20.1613 18.288 19.6739 18.9176 18.9811 19.1853L11.7967 21.9613C11.3172 22.1466 10.7848 22.1402 10.3099 21.9433L3.64278 19.1791C2.99974 18.9125 2.54159 18.3308 2.43316 17.6432L0.92935 8.1068C0.800448 7.28937 1.18828 6.47698 1.90494 6.06322L10.1077 1.32735Z"
      fill="url(#paint0_linear_4_696)"
      fillOpacity="0.2"
      stroke="url(#paint1_linear_4_696)"
      strokeWidth="0.5"
    />
    <path
      d="M10.5501 11.1626L13.0144 6.16319L10.7714 5.94761L8.18169 12.5446L11.1723 12.8321L9.6426 17.9213L13.5407 11.45L10.5501 11.1626Z"
      fill="url(#paint2_linear_4_696)"
      stroke="url(#paint3_linear_4_696)"
      strokeWidth="0.5"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4_696"
        x1="0.715393"
        y1="11.5"
        x2="21.5"
        y2="11.4981"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#AA6535" />
        <stop offset="1" stopColor="#FBBF95" />
      </linearGradient>

      <linearGradient
        id="paint1_linear_4_696"
        x1="0.715393"
        y1="11.5"
        x2="21.5"
        y2="11.4981"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#AA6535" />
        <stop offset="1" stopColor="#FBBF95" />
      </linearGradient>

      <linearGradient
        id="paint2_linear_4_696"
        x1="8.24443"
        y1="11.7458"
        x2="13.4852"
        y2="12.1573"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#AA6535" />
        <stop offset="1" stopColor="#FBBF95" />
      </linearGradient>

      <linearGradient
        id="paint3_linear_4_696"
        x1="8.24443"
        y1="11.7458"
        x2="13.4852"
        y2="12.1573"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#AA6535" />
        <stop offset="1" stopColor="#FBBF95" />
      </linearGradient>
    </defs>
  </Icon>
);

export const icons = {ProfileIcon, HomeIcon, NotificationsIcon, LanguageSwitchIcon, EarnedBadges};
