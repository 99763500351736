import {AxiosError, AxiosResponse} from 'axios';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';

import {api, setApiToken} from '@eksab/api';
import * as cache from '@eksab/cache';
import {useFetchMe, useSetAccessToken} from '@eksab/hooks';
import {config} from '@eksab/config';
import {useAuthStore} from '../state';
import {sendTwitterCompeteteRegistrationEvent} from '@eksab/lib/sendTwitterEvents';

type Params = {
  name: string;
  phone_number: string;
  email?: string;
  otp: string;
  password: string;
  recaptcha: string;
  referrer?: string;
};

export type RegisterReturn = {
  access_token: string;
};

export function useRegisterMutation(options?: UseMutationOptions<RegisterReturn, AxiosError, Params>) {
  const setLoggedIn = useAuthStore((state) => state.setLoggedIn);
  const fetchMe = useFetchMe();
  const setAccessToken = useSetAccessToken();
  return useMutation<RegisterReturn, AxiosError, Params>(
    (params) =>
      api
        .post<Params, AxiosResponse<RegisterReturn>>('/api/register', params, {baseURL: config.baseURL})
        .then((res) => res.data),
    {
      ...options,
      onSuccess: async (...params) => {
        try {
          setLoggedIn(true);
          fbq?.('track', 'CompleteRegistration');

          setApiToken(params[0].access_token);
          setAccessToken(params[0].access_token);

          const me = await fetchMe();

          config.appEnv === 'production' && sendTwitterCompeteteRegistrationEvent(me);

          mixpanel?.alias(`${me.id}`);
          mixpanel?.track('Register');
          mixpanel?.track('New User');
          cache.setMe(me);

          options?.onSuccess?.(...params);
        } catch (e) {
          console.error(e);
        }
      },
    },
  );
}
