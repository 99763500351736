import Image from 'next/image';
import NextLink from 'next/link';
import {useRouter} from 'next/router';
import {useIntl} from 'react-intl';
import {Flex, FlexProps, LinkBox, LinkOverlay, Text} from '@chakra-ui/react';

import {useTranslate} from '@eksab/i18n';
import {ArrowForwardIcon, CoinsIcon} from '@eksab/assets/icons';
import {formatCoins, getLanguage} from '@eksab/util';

import {LightningIcon} from '@eksab/features/store/badges/icons';

import {EarnedBadge} from './';
import type {Milestone} from '../types';

interface EarnedBadgeCardProps extends FlexProps {
  milestone: Milestone;
  previewOnly?: boolean;
}

export function EarnedBadgeCard({milestone, previewOnly = false, ...props}: EarnedBadgeCardProps) {
  const t = useTranslate();

  const {locale} = useRouter();
  const format = useIntl().formatNumber;

  const isCollected = milestone.status === 'claimed';
  const numOfPowers = milestone.package.power_ups?.length ?? 0;

  return (
    <LinkBox
      as="article"
      className="group"
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap="2"
      px={[3, null, 4]}
      pb={previewOnly ? [7, null, 8] : [3.5, null, 4]}
      rounded="3xl"
      borderWidth="1px"
      borderColor={isCollected ? '#90d8a7' : 'gray.400'}
      bg={isCollected ? '#ccedd6' : '#fbfbfb'}
      {...props}
    >
      <MilestoneStatus status={milestone.status} progress={milestone.progress_percentage} pos="relative" top="-1px" />

      <EarnedBadge milestone={milestone} width={['52%', null, '62.5%']} mb="0.5" />

      <Text
        as="h4"
        fontSize={['sm', 'md', null, 'xl']}
        fontWeight="700"
        textAlign="center"
        noOfLines={1}
        wordBreak="break-all"
      >
        {milestone.name}
      </Text>

      {(!!milestone.package.coins || !!numOfPowers) && (
        <Flex
          align="center"
          gap="2"
          rounded="3xl"
          borderWidth="1px"
          color={isCollected ? 'white' : '#d8d8d8'}
          borderColor="currentcolor"
          bg={isCollected ? '#90d8a7' : 'white'}
          px="2.5"
          py="1.5"
          fontSize={['xs', null, 'sm']}
          fontWeight="700"
          mb="1"
        >
          {!!milestone.package.coins && (
            <Flex align="center" gap="0.5">
              <Text as="span">{t('profile.my-badges.earned.bonus-coins')}</Text>

              <CoinsIcon height="4" />

              <Text as="span" flexShrink="0">
                {formatCoins(milestone.package.coins)}
              </Text>
            </Flex>
          )}

          {!!milestone.package.coins && !!numOfPowers && <Flex bg="currentcolor" h="full" w="1px" />}

          {!!numOfPowers && (
            <Flex align="center" gap="0.5">
              <Image src={LightningIcon} alt="" height="20" />

              <Text as="span">+{format(numOfPowers)}</Text>
            </Flex>
          )}
        </Flex>
      )}

      {!previewOnly && (
        <LinkOverlay
          as={NextLink}
          href={`/profile/my-badges/earned/milestones/${milestone.id}`}
          mt="auto"
          display="flex"
          alignItems="center"
          gap="3.5"
          px={isCollected ? 5 : 8}
          py="1"
          minH={[8, null, null, 10]}
          rounded="full"
          borderWidth="1px"
          borderColor={isCollected ? '#d3d3d3' : milestone.status === 'completed' ? 'green.400' : '#b6b6b6'}
          fontSize={[isCollected ? 'x-small' : 'xs', null, 'sm']}
          fontWeight={isCollected ? 600 : 700}
          bg={isCollected ? 'white' : milestone.status === 'completed' ? 'green.400' : '#b6b6b6'}
          color={isCollected ? 'black' : 'white'}
        >
          {t(`profile.my-badges.earned.cta.${isCollected ? 'info' : 'unlock'}`)}

          {isCollected && (
            <ArrowForwardIcon
              color="black"
              strokeWidth="1.5"
              transform="auto"
              scaleX={getLanguage(locale) === 'ar' ? -1 : 1}
            />
          )}
        </LinkOverlay>
      )}
    </LinkBox>
  );
}

interface MilestoneStatusProps extends FlexProps {
  progress: number;
  status: Milestone['status'];
}

const MilestoneStatus = ({status, progress, ...props}: MilestoneStatusProps) => {
  const t = useTranslate();

  const format = useIntl().formatNumber;

  return (
    <Flex
      gap="1"
      align="center"
      justify="center"
      textAlign="center"
      px="2"
      py="1.5"
      minW={[24, 28, null, 32]}
      roundedBottom="lg"
      bg={status === 'in-progress' ? 'yellow.300' : status === 'completed' ? '#CCEDD6' : 'green.400'}
      {...props}
    >
      <Text
        fontSize={['xs', null, 'sm', null, 'md']}
        fontWeight={[600, null, status === 'completed' ? 800 : 700]}
        color={status === 'in-progress' ? 'black' : status === 'completed' ? 'green.400' : 'white'}
        noOfLines={1}
      >
        {t(`profile.my-badges.earned.status.${status}`)}
      </Text>

      {status === 'in-progress' && (
        <Text p="0.5" bg="yellow.50" fontSize="x-small" fontWeight="700" color="green.400" rounded="sm">
          {format(progress, {style: 'percent', maximumFractionDigits: 0})}
        </Text>
      )}
    </Flex>
  );
};
