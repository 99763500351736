import NextLink from 'next/link';
import {useRouter} from 'next/router';
import {useIntl} from 'react-intl';
import {FlexProps, Flex, Center} from '@chakra-ui/react';
import {ArrowForwardIcon} from '@chakra-ui/icons';

import {useTranslate} from '@eksab/i18n';
import {getLanguage} from '@eksab/util';

interface ReadyToUnlockButtonProps extends FlexProps {
  readyToUnlockMilestones?: number;
}

export const ReadyToUnlockButton = ({readyToUnlockMilestones, ...props}: ReadyToUnlockButtonProps) => {
  const {locale} = useRouter();
  const format = useIntl().formatNumber;

  const t = useTranslate();

  return (
    <Flex
      as={NextLink}
      href="/profile/my-badges/earned/ready-to-unlock"
      gap="1"
      align="center"
      px={readyToUnlockMilestones ? 2 : 4}
      py="2"
      fontSize={['xs', 'sm', 'md']}
      fontWeight="600"
      borderWidth="1px"
      borderColor="yellow.50"
      rounded="full"
      color="red.400"
      bg="#FFF9EE"
      transition="color 0.3s, background 0.3s, border-color 0.5s"
      _hover={{borderColor: 'currentColor'}}
      _active={{bg: 'yellow.50', color: 'red.300'}}
      {...props}
    >
      {!!readyToUnlockMilestones && (
        <Center rounded="full" bg="red.400" boxSize={[6, 7, 8]} fontWeight="700" color="white">
          {format(readyToUnlockMilestones)}
        </Center>
      )}

      {t('profile.my-badges.earned.cta.unlock')}

      <Center boxSize="4">
        <ArrowForwardIcon boxSize="3.5" transform="auto" scaleX={getLanguage(locale) === 'ar' ? -1 : 1} />
      </Center>
    </Flex>
  );
};
