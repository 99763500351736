import {PropsWithChildren, useEffect} from 'react';
import {Box} from '@chakra-ui/layout';
import {useDisclosure} from '@chakra-ui/react-use-disclosure';

import {useSyncWebViewAuth, useHotJar, useSocket, useSocketStore} from '@eksab/hooks';

import {NewlyEarnedBadgeModal} from '@eksab/features/profile/badges/components/NewlyEarnedBadgeModal';

import {useMobileNavHeight} from './footer';
import {MilestoneDetails} from '@eksab/features/profile/badges/types';

export function Main({children}: PropsWithChildren<{}>) {
  const newlyEarnedBadgeDisclosure = useDisclosure();

  const mobileNavHeight = useMobileNavHeight((state) => state.bottomNavHeight);
  useHotJar();
  useSyncWebViewAuth();
  const {addHandler, removeHandler} = useSocket();

  useEffect(() => {
    addHandler('packages.earned_badges.user_won_badge', (milestone: MilestoneDetails) => {
      useSocketStore.setState({milestone});
      newlyEarnedBadgeDisclosure.onOpen();
    });

    return () => {
      removeHandler('packages.earned_badges.user_won_badge');
    };
  }, []);

  return (
    <>
      <Box as="main" flex="1" display="flex" flexDir="column" pb={[mobileNavHeight, null, null, 0]}>
        {children}
      </Box>

      <NewlyEarnedBadgeModal {...newlyEarnedBadgeDisclosure} />
    </>
  );
}
