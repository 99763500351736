import {QueryFunctionContext, QueryKey, useQueryClient} from '@tanstack/react-query';

import {api} from '@eksab/api';
import {getCountry, getLanguage} from '@eksab/util';

import {useQuery} from './useQuery';

type FeatureFlag =
  | 'tactic_enabled'
  | '2_sides_document'
  | 'payment_methods.fawry'
  | 'payment_methods.cc'
  | 'payment_methods.wallets'
  | 'coin_packages_enabled'
  | 'show_licence_details'
  | 'badge_packages_enabled'
  | 'show_prize_redemption_legal_forms'
  | 'show_latest_winners'
  | 'show_groups'
  | 'shipping_available';

export type RegionSettings = {currency: string; settings: Record<FeatureFlag, boolean>};

const queryKey = 'region-settings';

const queryFn = (context: QueryFunctionContext<QueryKey>) => {
  const locale = context.queryKey[context.queryKey.length - 1] as string;
  return api
    .get<{data: RegionSettings}>('v3/regions/current', {
      headers: {'X-REGION': getCountry(locale), 'X-Locale': getLanguage(locale)},
    })
    .then((res) => res.data.data);
};

export function useRegionSettings() {
  return useQuery<RegionSettings>([queryKey], queryFn);
}

export function useGetRegionSettingsQueryData() {
  const queryClient = useQueryClient();
  return () => queryClient.getQueryData<RegionSettings>([queryKey], {exact: false});
}

useRegionSettings.queryKey = queryKey;
useRegionSettings.queryFn = queryFn;
