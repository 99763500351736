import {config} from '@eksab/config';

type FeatureFlags = 'trivia';

const featureFlags: Record<FeatureFlags, (typeof config.appEnv)[]> = {
  trivia: ['development', 'staging', 'production'],
};

export function isFeatureEnabled(flag: FeatureFlags) {
  return featureFlags[flag].includes(config.appEnv);
}
