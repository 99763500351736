import {useEffect, useCallback} from 'react';
import {type Socket, io} from 'socket.io-client';
import {create} from 'zustand';
import {config} from '@eksab/config';
import type {MilestoneDetails} from '@eksab/features/profile/badges/types';
import {useAccessToken} from './useAccessToken';

interface ClientToServerEvents {
  register: (userID: number) => void;
}

export type ChallengePrizeAttachedEventPayload = {
  challenge_id: string;
  winner_attempt_id: string;
  user_prize_id: number;
};

export type ChallengeClosedEventPayload = {
  challenge_id: string;
  winner_attempt_id: string;
};

export interface ServerToClientEvents {
  'packages.earned_badges.user_won_badge': (milestone: MilestoneDetails) => void;
  'trivia.challenges.prize_attached': (payload: ChallengePrizeAttachedEventPayload) => void;
  'trivia.challenges.closed': (payload: ChallengeClosedEventPayload) => void;
}

type EventName = keyof ServerToClientEvents;

type Handler = ServerToClientEvents[keyof ServerToClientEvents];

type AppSocket = Socket<ServerToClientEvents, ClientToServerEvents>;

let connection: AppSocket;

export const useSocket = () => {
  const {data: token} = useAccessToken();

  useEffect(() => {
    if (!token) return;

    if (connection?.connected) return;

    connection = io(config.socketURL, {
      extraHeaders: {Authorization: `Bearer ${token}`},
    });

    return () => {
      connection.disconnect();
    };
  }, [token]);

  const addHandler = useCallback((eventName: EventName, handler: Handler) => {
    connection?.on(eventName, handler);
  }, []);

  const removeHandler = useCallback((eventName: EventName) => {
    connection?.off(eventName);
  }, []);

  return {addHandler, removeHandler};
};

interface SocketStoreActions {
  reset: () => void;
}

interface SocketStoreState {
  milestone: MilestoneDetails | null;
}

type SocketStore = SocketStoreState & SocketStoreActions;

const initialState: SocketStoreState = {
  milestone: null,
};

export const useSocketStore = create<SocketStore>((set) => ({
  ...initialState,
  reset: () => set(initialState),
}));
