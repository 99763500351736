import {ProductTour, type ProductTourStep} from '@eksab/components/product-tour';

import {CirclesGridIcon, StarIcon, LockOpenIcon} from '../icons';

interface BadgesTourProps {
  shouldRun: boolean;
  onFinish?: () => void;
}

export const BadgesTour = ({shouldRun, onFinish}: BadgesTourProps) => (
  <ProductTour name="badges_tour" steps={steps} run={shouldRun} onFinish={onFinish} />
);

const steps: ProductTourStep[] = [
  {
    target: '#earned-badges-intro',
    title: 'profile.my-badges.earned.tour.step-1.title',
    content: 'profile.my-badges.earned.tour.step-1.content',
    icon: (props) => <StarIcon color="#d79314" {...props} />,
    disableBeacon: true,
    isFixed: false,
    placement: 'auto',
  },
  {
    target: '#ready-to-unlock-button',
    title: 'profile.my-badges.earned.tour.step-2.title',
    content: 'profile.my-badges.earned.tour.step-2.content',
    icon: LockOpenIcon,
    disableBeacon: true,
    isFixed: false,
    placement: 'top',
  },
  {
    target: '#earned-badges-section .view-all-button:first-of-type',
    title: 'profile.my-badges.earned.tour.step-3.title',
    content: 'profile.my-badges.earned.tour.step-3.content',
    icon: (props) => <CirclesGridIcon {...props} width="3.5" />,
    disableBeacon: true,
    isFixed: false,
    placement: 'top',
  },
];
