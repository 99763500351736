import {useIntl} from 'react-intl';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Box,
  Text,
  Center,
  Flex,
  ModalFooter,
} from '@chakra-ui/react';
import {useTranslate} from '@eksab/i18n';
import type {MyBadge} from '../types';
import {Badge} from '@eksab/features/store/badges/components';
import {ClockIcon} from '../icons';
import {CardsIcon} from '../../icons';
import {TagIcon} from '@eksab/features/store/badges/icons';

type BadgeDetailsModalProps = MyBadge & {
  isOpen: boolean;
  onClose: () => void;
};

export const BadgeDetailsModal = ({badge, count, last_purchase, isOpen, onClose}: BadgeDetailsModalProps) => {
  const formatNumber = useIntl().formatNumber;
  const formatDate = useIntl().formatDate;

  const t = useTranslate();

  return (
    <Modal isOpen={isOpen} onClose={onClose} motionPreset="scale" variant="full" size="lg">
      <ModalOverlay />

      <ModalContent gap="10" pt="10" pb="8" rounded="xl" shadow="sm" minH={{base: '100vh', lg: '70vh'}}>
        <ModalBody display="flex" flexDir="column" alignItems="center" gap="1" py="0" textAlign="center">
          <Text fontSize="xs" fontWeight="600" color="#6C6C6C">
            {t('profile.my-badges.latest.purchase')}
          </Text>

          <Flex mb="2" gap="2">
            <ClockIcon />
            <Text fontSize="xs" fontWeight="700" color="#2A2E3A">
              {formatDate(last_purchase, {
                hour: 'numeric',
                minute: '2-digit',
                day: 'numeric',
                month: 'short',
                year: undefined,
              })}
            </Text>
          </Flex>
          <Flex
            fontSize="xs"
            align="center"
            gap="1"
            borderWidth="1px"
            bg="#2A2E3A"
            px="3"
            py="1.5"
            rounded="3xl"
            color="#FFFFFF"
            fontWeight="700"
          >
            <TagIcon />
            <Text fontSize="xs" fontWeight="700" color="#DAE2DA">
              {t('profile.my-badges.badge.code')}
            </Text>
            <Box as="span" w="1px" h="5" mx="auto" />
            <span>#</span>
            <Text fontSize="sm" fontWeight="700" color="#5AC57B">
              {badge?.id}
            </Text>
          </Flex>

          <Badge badge={badge} mt={{base: 14, lg: 4}} w="full" maxW="96" />
          <Center flexDir="row" justifyContent="space-evenly" gap="2" bg="#EAEAEA" rounded="44px" p="2">
            <Box bg="#FFFFFF" borderRadius="full">
              <CardsIcon width={{base: 6, lg: 8}} height="auto" p="1" />
            </Box>
            <Text align="start" fontSize="xs" fontWeight="600">
              {formatNumber(count)}x
            </Text>
          </Center>
        </ModalBody>

        <ModalFooter p="0">
          <ModalCloseButton
            size="lg"
            color={{base: 'white', lg: 'black'}}
            backgroundColor={{base: 'black', lg: 'white'}}
            rounded={{base: 'full', lg: 'none'}}
            pos={{base: 'static', lg: 'absolute'}}
            _hover={{}}
            mx="auto"
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
