import {useCallback, useEffect} from 'react';
import Image from 'next/image';
import {useRouter} from 'next/router';
import {create} from 'zustand';
import {
  Center,
  CenterProps,
  Flex,
  Icon,
  IconProps,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import {px} from '@eksab/theme';
import {useTranslate} from '@eksab/i18n';
import {useShouldPromoteMobileApp, useWebViewEffect} from '@eksab/hooks';

const ANDROID_APP_SCHEME = 'com.eksab.eksab://';

export function OpenInApp() {
  const {push} = useRouter();
  const openInAppDisclosure = useDisclosure({defaultIsOpen: true});

  const t = useTranslate();
  const {height, showOpenInApp, setShowOpenInApp} = useOpenInApp();

  useWebViewEffect(
    useCallback(() => {
      if (!showOpenInApp) return;

      setShowOpenInApp(false);
    }, [setShowOpenInApp, showOpenInApp]),
  );

  const onOpenApp = async () => {
    mixpanel?.track('Button Clicked', {button: 'open-in-app', os: 'android'});

    push(ANDROID_APP_SCHEME);

    setTimeout(() => {
      const hasOpenedApp = !document.hasFocus();

      if (!hasOpenedApp) push('/download');
    }, 500);

    openInAppDisclosure.onClose();
  };

  if (!showOpenInApp) return null;

  return (
    <>
      <Flex
        zIndex="popover"
        bg="white"
        align="center"
        gap="4"
        justify="space-between"
        px={px}
        height={height}
        outline="1px"
        outlineColor="gray.400"
        overflow="hidden"
      >
        <Flex align="center" gap="2">
          <Center boxSize="10" bg="black" p="1" rounded="sm">
            <Image src="/logos/eksab-split.svg" alt="Eksab Logo" width="32" height="32" />
          </Center>

          <Flex direction="column" lineHeight="1.4">
            <Text fontSize="sm" fontWeight="600">
              {t('open-in-app.banner.title')}
            </Text>

            <Text fontSize="xs" color="gray.500" lineHeight="1">
              {t('open-in-app.banner.subtitle')}
            </Text>
          </Flex>
        </Flex>

        <Button alignSelf="center" fontSize="sm" onClick={onOpenApp}>
          {t('open-in-app.banner.cta')}
        </Button>
      </Flex>

      <Modal {...openInAppDisclosure} variant="full">
        <ModalOverlay />

        <ModalContent gap="4" px={px} py="4" rounded="xl">
          <ModalHeader p="0">
            <Text fontSize="md" textAlign="center">
              {t('open-in-app.modal.title')}
            </Text>
          </ModalHeader>

          <ModalBody p="0" fontSize="sm" display="flex" flexDir="column" gap="4">
            <Flex align="center" gap="4">
              <Center boxSize="6" bg="black" p="0.5" rounded="sm">
                <Image src="/logos/eksab-split.svg" alt="Eksab Logo" width="20" height="20" />
              </Center>

              <Text fontSize="inherit">{t('open-in-app.modal.option.app.title')}</Text>

              <Button ms="auto" onClick={onOpenApp}>
                {t('open-in-app.modal.option.app.cta')}
              </Button>
            </Flex>

            <Flex align="center" gap="4">
              <BrowserIcon boxSize="6" />

              <Text fontSize="inherit">{t('open-in-app.modal.option.browser.title')}</Text>

              <Button
                bg="transparent"
                color="gray.700"
                borderWidth="1px"
                borderColor="current"
                ms="auto"
                onClick={openInAppDisclosure.onClose}
              >
                {t('open-in-app.modal.option.browser.cta')}
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

const Button = (props: CenterProps) => (
  <Center
    as="button"
    bg="#1a73e8"
    fontWeight="600"
    textAlign="center"
    color="white"
    px="4"
    minH="8"
    rounded="full"
    outline="none"
    {...props}
  />
);

const BrowserIcon = (props: IconProps) => (
  <Icon width="20px" height="20px" viewBox="0 0 20 20" fill="none" color="black" {...props}>
    <path
      d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM16.9 6H13.6C13.3 4.5 12.7 3.2 12.1 2.3C14.1 2.8 15.9 4.2 16.9 6ZM18 10C18 11.1 17.8 12.1 17.4 13H13.8C13.9 12.1 14 11.1 14 10C14 8.9 13.9 7.9 13.8 7H17.4C17.8 7.9 18 8.9 18 10ZM10 18C9.2 18 8.1 16.5 7.4 14H12.5C11.9 16.5 10.8 18 10 18ZM7.2 13C7.1 12.1 7 11.1 7 10C7 8.9 7.1 7.9 7.2 7H12.7C12.9 7.9 12.9 8.9 12.9 10C12.9 11.1 12.8 12.1 12.7 13H7.2ZM2 10C2 8.9 2.2 7.9 2.6 7H6.2C6.1 7.9 6 8.9 6 10C6 11.1 6.1 12.1 6.2 13H2.6C2.2 12.1 2 11.1 2 10ZM10 2C10.8 2 11.9 3.5 12.6 6H7.4C8.1 3.5 9.2 2 10 2ZM8 2.3C7.3 3.2 6.8 4.5 6.4 6H3.1C4.1 4.2 5.9 2.8 8 2.3ZM3.1 14H6.4C6.7 15.5 7.3 16.8 7.9 17.7C5.9 17.2 4.1 15.8 3.1 14ZM12 17.7C12.7 16.8 13.2 15.5 13.5 14H16.8C15.9 15.8 14.1 17.2 12 17.7Z"
      fill="currentColor"
      fillOpacity="0.6"
    />
  </Icon>
);

type OpenInAppStore = {
  showOpenInApp: boolean | null;
  height: string;
};

const useOpenInAppStore = create<OpenInAppStore>(() => ({
  height: '3.5rem',
  showOpenInApp: null,
}));

export const useOpenInApp = () => {
  const shouldShowOpenInApp = useShouldPromoteMobileApp();

  const height = useOpenInAppStore((state) => state.height);
  const showOpenInApp = useOpenInAppStore((state) => state.showOpenInApp);
  const setShowOpenInApp = useCallback(
    (isShown: boolean) => {
      if (!isAndroid()) return;

      if (!shouldShowOpenInApp) return;

      useOpenInAppStore.setState({showOpenInApp: isShown});
    },
    [shouldShowOpenInApp],
  );

  useEffect(() => {
    const isOpenInAppSet = useOpenInAppStore.getState().showOpenInApp !== null;
    if (isOpenInAppSet) return;

    setShowOpenInApp(true);
  }, [setShowOpenInApp]);

  return {height, showOpenInApp, setShowOpenInApp};
};

const isAndroid = () => !/windows phone/i.test(navigator.userAgent) && /android/i.test(navigator.userAgent);
