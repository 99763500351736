import {useMeQuery} from '@eksab/hooks';
import {config} from '@eksab/config';
import {useEffect} from 'react';

export function useHotJar() {
  const {data: me} = useMeQuery();

  useEffect(() => {
    if (config.appEnv === 'production' || true) {
      getHotjarLibrary().then((lib) => {
        lib.hotjar.initialize(parseInt(config.hotjarId as string), 6);
      });
    }
  }, []);

  useEffect(() => {
    if (config.appEnv === 'production' || true) {
      getHotjarLibrary().then((lib) => {
        if (me) {
          lib.hotjar.identify(me.id.toString(), {});
        }
      });
    }
  }, [me]);

  const getHotjarLibrary = () => {
    return import('react-hotjar');
  };
}
