import numeral from 'numeral';

export const isServer = () => typeof window === 'undefined';

interface copyToClipboardOptions {
  onSuccess?: (text: string) => void;
  onError?: (e: any) => void;
}

export const copyToClipboard = (text: string, options?: copyToClipboardOptions) =>
  navigator.clipboard
    .writeText(text)
    .then(() => options?.onSuccess?.(text))
    .catch((e) => options?.onError?.(e));

export const getLanguage = (locale: string) => locale.split('-')[0] as 'en' | 'ar';

export const getCountry = (locale: string) => locale.split('-')[1] as 'EG' | 'AE' | 'MA' | 'SA';

const ARABIC_DIGITS = '٠١٢٣٤٥٦٧٨٩';

export const formatCoins = (coins: number) => {
  if (numeral.locale() === 'ar') {
    return numeral(coins)
      .format('0[.]0 a', (n) => ~~n)
      .replace(/\d/g, (d) => ARABIC_DIGITS[d as unknown as number]);
  }

  return numeral(coins)
    .format('0[.]0a', (n) => ~~n)
    .toUpperCase();
};

export const forceEnglishDigits = (num: string) => {
  let lastCharAscii = num.charCodeAt(num.length - 1);

  const isArabicDigit = lastCharAscii >= '٠'.charCodeAt(0) && lastCharAscii <= '٩'.charCodeAt(0);
  if (isArabicDigit) {
    // Transform Arabic digits into English: ٠،١،٢،٣،.. into 0,1,2,3
    lastCharAscii -= '٠'.charCodeAt(0) - '0'.charCodeAt(0);
    num = num.slice(0, -1) + String.fromCharCode(lastCharAscii);
  }

  return num;
};

export const remToPx = (rem: string) => {
  return (
    Number(rem.split('rem')[0]) * parseFloat(isServer() ? '16px' : getComputedStyle(document.documentElement).fontSize)
  );
};

export const prefetchImage = (url: string) => {
  const img = new Image();
  img.src = url;
  return img;
};
