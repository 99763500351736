import {IconProps, Icon} from '@chakra-ui/react';

export const TriviaIcon = (props: IconProps) => (
  <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" {...props}>
    <g clipPath="url(#clip0_3441_16331)">
      <path d="M22.125 9.22101C21.822 8.97701 21.402 8.93301 21.053 9.10701L15.881 11.693L13.358 7.48701C12.997 6.88401 12.004 6.88401 11.643 7.48701L9.11995 11.693L3.94695 9.10601C3.59795 8.93201 3.17795 8.97601 2.87495 9.22001C2.56995 9.46601 2.43595 9.86401 2.52895 10.243L4.31795 17.396C3.82395 17.761 3.49995 18.342 3.49995 19.001V20.001C3.49995 21.104 4.39595 22.001 5.49995 22.001H19.5C20.604 22.001 21.5 21.104 21.5 20.001V19.001C21.5 18.342 21.176 17.761 20.682 17.397L22.471 10.244C22.564 9.86401 22.43 9.46601 22.125 9.22101ZM9.05296 13.896C9.51996 14.129 10.089 13.964 10.358 13.516L12.5 9.94401L14.643 13.515C14.912 13.963 15.481 14.128 15.948 13.895L20.003 11.868L18.72 17.001H6.28095L4.99795 11.868L9.05296 13.896ZM19.497 20.001H5.49995V19.001H19.5L19.497 20.001Z" />
      <path d="M12.5 6.00101C13.6046 6.00101 14.5 5.10558 14.5 4.00101C14.5 2.89644 13.6046 2.00101 12.5 2.00101C11.3954 2.00101 10.5 2.89644 10.5 4.00101C10.5 5.10558 11.3954 6.00101 12.5 6.00101Z" />
    </g>
    <defs>
      <clipPath id="clip0_3441_16331">
        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </Icon>
);
