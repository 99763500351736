import {Box, Container, Text, Image, Flex} from '@chakra-ui/react';
import dayjs from 'dayjs';

import {useTranslate} from '@eksab/i18n';
import {ScrollableFades} from '@eksab/components/ScrollableFades';

import {useTodayMatchesQuery} from './useTodayMatchesQuery';

export function TodayMatches() {
  const t = useTranslate();
  const todayMatchesQuery = useTodayMatchesQuery();

  return todayMatchesQuery.isSuccess && todayMatchesQuery.data.length === 0 ? (
    <></>
  ) : (
    <Box bgColor="gray.900" maxW="unset">
      <Container pt="4" pb="8" flexDirection="column" bgColor="gray.900" pos="relative">
        <Text fontSize="sm" fontWeight="bold" color="green.400" mb="4">
          {t('home.today_matches')}
        </Text>
        <Container variant="scroll" display="flex" gap="5">
          <ScrollableFades bgColor="gray.900" />
          {todayMatchesQuery.data?.map((match) => <Match match={match} key={match.id} />)}
        </Container>
      </Container>
    </Box>
  );
}

function Match({match}: {match: MatchT}) {
  return (
    <Flex bgColor="gray.700" borderRadius="md" alignItems="center" justifyContent="space-between" flexShrink={0} p="4">
      <Image src={match.home_team.logo?.thumb ?? undefined} alt="home team" w="31px" h="31px" />
      <Text color="white" noOfLines={1} as="span" fontWeight="700">
        {match.home_team.name}
      </Text>

      <Text color="white" mx={4}>
        {dayjs(match.start).format('HH:mm')}
      </Text>

      <Text color="white" noOfLines={1} as="span" fontWeight="700">
        {match.away_team.name}
      </Text>

      <Image src={match.away_team.logo?.thumb ?? undefined} alt="home team" w="31px" h="31px" me="2" />
    </Flex>
  );
}
