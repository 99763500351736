import {useIntl} from 'react-intl';
import {Flex, FlexProps, Icon, IconProps} from '@chakra-ui/react';

type EarnedBadgesCountProps = FlexProps & {
  count: number;
};

export const EarnedBadgesCount = <T extends EarnedBadgesCountProps>({count, ...props}: T) => {
  const format = useIntl().formatNumber;

  return (
    <Flex
      align="center"
      gap="1.5"
      px={[2, 2.5]}
      py="1"
      borderWidth="1px"
      borderColor={['#eaeaea', null, null, '#6c6c6c']}
      rounded="full"
      fontSize={['xs', 'sm']}
      fontWeight="800"
      color="white"
      bg="black"
      {...props}
    >
      {format(count)}

      <EarnedBadges flexShrink="1" width="full" height="auto" minW="4" maxW="5" maxH="full" />
    </Flex>
  );
};

const EarnedBadges = (props: IconProps) => (
  <Icon width="22px" height="23px" viewBox="0 0 22 23" fill="none" {...props}>
    <path
      d="M10.1077 1.32735C10.7265 0.970084 11.4889 0.970085 12.1077 1.32735L20.3619 6.0929C21.0527 6.49176 21.4405 7.26303 21.3485 8.05545L20.2469 17.5503C20.1613 18.288 19.6739 18.9176 18.9811 19.1853L11.7967 21.9613C11.3172 22.1466 10.7848 22.1402 10.3099 21.9433L3.64278 19.1791C2.99974 18.9125 2.54159 18.3308 2.43316 17.6432L0.92935 8.1068C0.800448 7.28937 1.18828 6.47698 1.90494 6.06322L10.1077 1.32735Z"
      fill="url(#paint0_linear_4_696)"
      fillOpacity="0.2"
      stroke="url(#paint1_linear_4_696)"
      strokeWidth="0.5"
    />
    <path
      d="M10.5501 11.1626L13.0144 6.16319L10.7714 5.94761L8.18169 12.5446L11.1723 12.8321L9.6426 17.9213L13.5407 11.45L10.5501 11.1626Z"
      fill="url(#paint2_linear_4_696)"
      stroke="url(#paint3_linear_4_696)"
      strokeWidth="0.5"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4_696"
        x1="0.715393"
        y1="11.5"
        x2="21.5"
        y2="11.4981"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#AA6535" />
        <stop offset="1" stopColor="#FBBF95" />
      </linearGradient>

      <linearGradient
        id="paint1_linear_4_696"
        x1="0.715393"
        y1="11.5"
        x2="21.5"
        y2="11.4981"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#AA6535" />
        <stop offset="1" stopColor="#FBBF95" />
      </linearGradient>

      <linearGradient
        id="paint2_linear_4_696"
        x1="8.24443"
        y1="11.7458"
        x2="13.4852"
        y2="12.1573"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#AA6535" />
        <stop offset="1" stopColor="#FBBF95" />
      </linearGradient>

      <linearGradient
        id="paint3_linear_4_696"
        x1="8.24443"
        y1="11.7458"
        x2="13.4852"
        y2="12.1573"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#AA6535" />
        <stop offset="1" stopColor="#FBBF95" />
      </linearGradient>
    </defs>
  </Icon>
);
