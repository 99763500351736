import {useEffect, useRef, useState} from 'react';
import Image, {StaticImageData} from 'next/image';
import {useRouter} from 'next/router';
import {useIntl} from 'react-intl';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalFooter,
  Text,
  Container,
  ModalProps,
  Flex,
  ModalCloseButton,
} from '@chakra-ui/react';
import {MotionValue, useMotionValue, useSpring} from 'framer-motion';

import {py} from '@eksab/theme';
import {useTranslate} from '@eksab/i18n';
import {BackIcon} from '@eksab/assets/icons/BackIcon';
import coinsIcon from '@eksab/assets/icons/coins-icon.png';
import {useSocketStore} from '@eksab/hooks';
import {getLanguage} from '@eksab/util';

import {LightningIcon} from '@eksab/features/store/badges/icons';

import {EarnedBadge, UnlockButton, WhatElseButton} from './';
import {useMilestoneQuery} from '../hooks';
import {milestoneGradientMap} from './earned-badge/const';

const bgGradient = milestoneGradientMap.expert;

type NewlyEarnedBadgeModalProps = Omit<ModalProps, 'children'>;

export function NewlyEarnedBadgeModal(props: NewlyEarnedBadgeModalProps) {
  const router = useRouter();
  const [isLocked, setIsLocked] = useState(true);

  const t = useTranslate();
  const socketMilestone = useSocketStore((state) => state.milestone);
  const milestoneQuery = useMilestoneQuery(socketMilestone?.id!);

  const milestone = milestoneQuery.data ?? socketMilestone;

  useEffect(() => {
    if (!props.isOpen || !milestone) return;

    mixpanel?.track('Badge Earned', {milestone});
  }, [milestone, props.isOpen]);

  if (!milestone) return null;

  const hasBenefits = !!milestone.package.coins || !!milestone.package.power_ups?.length;

  const onClose = () => {
    setIsLocked(true);
    props.onClose();
  };

  return (
    <Modal size="full" autoFocus={false} motionPreset="slideInBottom" {...props} onClose={onClose}>
      <ModalOverlay />

      <ModalContent
        display="grid"
        gridTemplateRows="auto auto"
        alignContent={{base: 'space-between', lg: 'start'}}
        py="3"
        _before={{
          content: '""',
          position: 'absolute',
          inset: 0,
          zIndex: '-1',
          opacity: '0.4',
          bg: `linear-gradient(139deg, ${bgGradient.color1} 4%, ${bgGradient.color2} 51.5%, ${bgGradient.color3} 95%)`,
        }}
      >
        <Container
          display={{base: 'none', lg: 'flex'}}
          py="2"
          alignItems="center"
          gap="3"
          cursor="pointer"
          onClick={onClose}
        >
          <BackIcon transform="auto" scaleX={getLanguage(router.locale) === 'ar' ? -1 : 1} />

          <Text fontSize="sm" fontWeight="500" color="black">
            {t('app.actions.go-back')}
          </Text>
        </Container>

        <Container flexDir="column" py={py} textAlign="center">
          <ModalBody display="flex" flexDir="column" alignItems="center" p="0">
            <Text as="h1" fontSize="3xl" fontWeight="800" mb="6">
              {milestone.name}
            </Text>

            <EarnedBadge milestone={milestone} w="full" maxW="56" mb="8" />

            <Text
              color="white"
              fontSize="md"
              fontWeight="800"
              px="4"
              py="2.5"
              rounded="full"
              borderBottomWidth="2px"
              borderColor="#ffc233"
              bg="linear-gradient(93deg, #f3189b, #fb6b02 92%)"
              shadow="0px 4px 6px 0px rgba(0, 0, 0, 0.3)"
              mb="4"
            >
              {t('profile.my-badges.earned.congratulations')}
            </Text>

            <Text fontSize="xl" fontWeight="600" maxW="80" mb="7">
              {milestone.progress_description}
            </Text>

            {hasBenefits && (
              <Flex gap="14">
                {!!milestone.package.coins && (
                  <BadgeBenefit
                    icon={coinsIcon}
                    label={t('store.packages.benefits.coins')}
                    value={milestone.package.coins}
                    onAnimationComplete={() => setIsLocked(false)}
                  />
                )}

                {!!milestone.package.power_ups?.length && (
                  <BadgeBenefit
                    icon={LightningIcon}
                    label={t('store.packages.benefits.powers.bonus')}
                    value={milestone.package.power_ups.length}
                  />
                )}
              </Flex>
            )}
          </ModalBody>

          <ModalFooter alignSelf="center" p="0" mt="12" flexDir="column" gap="3" minW={['full', 72]}>
            <UnlockButton
              tabIndex={1}
              milestoneID={milestone.id}
              w="full"
              isDisabled={isLocked || milestone.status === 'claimed'}
            />

            <WhatElseButton
              milestoneGroupID={milestone.group.id}
              w="full"
              color="#424242"
              bg="white"
              borderColor="white"
              onClick={onClose}
            />
          </ModalFooter>
        </Container>

        <ModalCloseButton
          mt="2"
          justifySelf="center"
          display={{base: 'flex', lg: 'none'}}
          size="lg"
          color="white"
          backgroundColor="black"
          rounded="full"
          pos="static"
          _hover={{}}
        />
      </ModalContent>
    </Modal>
  );
}

interface BadgeBenefitProps {
  icon: StaticImageData;
  label: string;
  value: number;
  onAnimationComplete?: () => void;
}

const BadgeBenefit = ({label, value, icon, onAnimationComplete}: BadgeBenefitProps) => {
  const textRef = useCounter({
    to: value,
    onAnimationComplete: onAnimationComplete,
  });

  return (
    <Flex flexDir="column" align="center">
      <Image height="24" src={icon} alt="" />

      <Text ref={textRef} fontSize="1.375rem" lineHeight="1" fontWeight="800" mt="3" mb="2.5" />

      <Text color="#6c6c6c" fontSize="xs" lineHeight="1" fontWeight="600">
        {label}
      </Text>
    </Flex>
  );
};

interface CounterProps {
  to: number;
  from?: number;
  onAnimationComplete?: () => void;
}

const useCounter = ({from = 0, to, onAnimationComplete}: CounterProps) => {
  const format = useIntl().formatNumber;
  const textRef = useRef<HTMLParagraphElement>(null);
  const _from = useMotionValue(from);
  const springValue: MotionValue<number> = useSpring(_from, {
    damping: 70,
    stiffness: 120,
  });

  useEffect(() => {
    _from.set(to);
  }, [_from, to]);

  useEffect(
    () =>
      springValue.onChange((latest) => {
        if (!textRef.current) return;

        if (latest === to) onAnimationComplete?.();

        textRef.current.textContent = format(latest, {maximumFractionDigits: 0});
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [springValue],
  );

  return textRef;
};
