import {Icon, IconProps} from '@chakra-ui/react';

export function MenuIcon(props: IconProps) {
  return (
    <Icon width="24px" height="25px" viewBox="0 0 24 25" fill="none" {...props}>
      <path
        d="M4.90948 18.5825H14.9137C15.414 18.5825 15.8232 18.1325 15.8232 17.5825C15.8232 17.0325 15.414 16.5825 14.9137 16.5825H4.90948C4.40927 16.5825 4 17.0325 4 17.5825C4 18.1325 4.40927 18.5825 4.90948 18.5825ZM4.90948 13.5825H12.1853C12.6855 13.5825 13.0948 13.1325 13.0948 12.5825C13.0948 12.0325 12.6855 11.5825 12.1853 11.5825H4.90948C4.40927 11.5825 4 12.0325 4 12.5825C4 13.1325 4.40927 13.5825 4.90948 13.5825ZM4 7.58249C4 8.13249 4.40927 8.58249 4.90948 8.58249H14.9137C15.414 8.58249 15.8232 8.13249 15.8232 7.58249C15.8232 7.03249 15.414 6.58249 14.9137 6.58249H4.90948C4.40927 6.58249 4 7.03249 4 7.58249ZM19.734 15.4625L17.1147 12.5825L19.734 9.70249C20.0887 9.31249 20.0887 8.68249 19.734 8.29249C19.3793 7.90249 18.8063 7.90249 18.4516 8.29249L15.1866 11.8825C14.8319 12.2725 14.8319 12.9025 15.1866 13.2925L18.4516 16.8825C18.8063 17.2725 19.3793 17.2725 19.734 16.8825C20.0796 16.4925 20.0887 15.8525 19.734 15.4625Z"
        fill="white"
      />
    </Icon>
  );
}
