import {Collapse, Alert, AlertIcon, AlertDescription, CloseButton, useBreakpointValue} from '@chakra-ui/react';
import {useAppSettingsQuery, useRegionSettings} from '@eksab/hooks';
import {useCPAAlertDisclosure} from '@eksab/hooks/useCPALicenseState';
import {useTranslate} from '@eksab/i18n';
import {useRouter} from 'next/router';
import {useEffect} from 'react';
import {useIntl} from 'react-intl';

export const CPALicense = () => {
  const t = useTranslate();
  const {cpaAlertOpen, dismissCPAAlert, didUserDismiss, openCPAAlert} = useCPAAlertDisclosure();
  const locale = useRouter().locale;
  const {data: regionSettings} = useRegionSettings();
  const {data: appSettings} = useAppSettingsQuery();

  const format = useIntl().formatNumber;
  const formatDate = useIntl().formatDate;

  useEffect(() => {
    if (!didUserDismiss) {
      if (!!regionSettings?.settings?.show_licence_details) openCPAAlert();
    }
  }, [didUserDismiss, locale, openCPAAlert, regionSettings?.settings?.show_licence_details]);

  return (
    <Collapse in={cpaAlertOpen} animateOpacity>
      <Alert
        colorScheme="green"
        status="success"
        variant="subtle"
        justifyContent="center"
        py="1"
        h={useCPAAlertHeight()}
      >
        <AlertIcon ms="auto" />
        <AlertDescription alignItems="center">
          {t('app.cpa-license', {
            b: (chunk) => <b>{chunk}</b>,
            number: appSettings?.licence_details?.number,
            date: formatDate(appSettings?.licence_details?.date, {month: 'short', year: 'numeric'}),
          })}
        </AlertDescription>
        <CloseButton onClick={dismissCPAAlert} ms="auto" />
      </Alert>
    </Collapse>
  );
};

export function useCPAAlertHeight() {
  const cpaAlertOpen = useCPAAlertDisclosure((state) => state.cpaAlertOpen);
  const cpaAlertHeight = useBreakpointValue(['3.5rem', null, null, '2.5rem'])!;

  return cpaAlertOpen ? cpaAlertHeight : '0rem';
}
